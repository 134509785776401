import { Spin } from 'antd';

const Loading = () => {
  return (
    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f8f8f8]">
      <Spin size="large" />
    </div>
  );
};

export default Loading;
