import { createLocaleMessages } from '@/utils/i18n';
import { basicColumnTitleZhCN } from './basicColumnTitle/zh-CN';
import { DefineLocalConfig } from './typings';

export default createLocaleMessages<DefineLocalConfig>({
  scanCode: {
    success: {
      title: '扫描成功',
      des: '在微信中轻触允许即可登录',
    },
    reject: {
      title: '你已取消此次登录',
      des: '你可再次扫描登录，或关闭窗口',
    },
  },
  menuCollapse: {
    expand: '收起边栏',
    collapse: '展开边栏',
  },
  agreePrivacy: '我已阅读并同意',
  checkPrivacy: '请阅读并勾选协议',
  autoRegisteredPhone: '，未注册的手机号将自动注册',
  and: '和',
  generateSuccess: '生成成功！请下载',
  home: {
    button: '开始',
    knows: {
      des: '中文提问，一键搜索全球最新文献、指南、会议，可让AI给出临床建议、学术综述或者科普内容。更可以一键生成专题报告PPT。',
      tooltip: '',
    },
    project: {
      des: '批量上传文献，使用AI表格工具，帮您高效提取文献数据、精准筛选文献，还能指定多篇文献进行实时AI问答。',
      tooltip: '',
    },
    read: {
      des: '上传PDF，30秒获得PPT版解读报告。\n我们针对各类文献设计不同解读方案，还支持实时问答，助您全面理解文献。',
      tooltip: '',
    },
    admin: {
      des: '管理企业账号',
      tooltip: '',
    },
    records: {
      des: '企业数据分析',
      tooltip: '',
    },
  },
  homeHUIZHI: {
    button: '开始',
    knows: {
      des: '快速获取学术科研建议与对应的证据列表，1分钟找出关键文献',
      tooltip: '',
    },
    project: {
      des: '快速查找文献，进行综述解读',
      tooltip: '',
    },
    read: {
      des: '精准解析学术PDF原文，辅助原文速读和精读，提升看文献效率',
      tooltip: '',
    },
    admin: {
      des: '企业管理',
      tooltip: '',
    },
    records: {
      des: '企业数据分析',
      tooltip: '',
    },
  },
  bindPhone: {
    info: '请绑定手机号后使用',
  },
  welcome: {
    enter: '进入产品',
    slogon: 'AI时代的医学搜索和创作平台',
    oneStop: '「知识」「见解」「创作」，AI一站式赋能医学知识学习和应用',
    start: '现在开始吧',
    mediflow: 'KnowS是什么？',
    ai: {
      title: 'AI搜索问答',
      des: '中文提问，一键搜索全球最新文献、指南、会议，可让AI给出临床建议、学术综述或者科普内容。更可以一键生成专题报告PPT。',
    },
    paper: {
      title: '证据分析',
      des: '批量上传文献，使用AI表格工具，帮您高效提取文献数据、精准筛选文献，还能指定多篇文献进行实时AI问答。',
    },
    read: {
      title: '全文精读',
      des: '上传PDF，30秒获得PPT版解读报告。\n我们针对各类文献设计不同解读方案，还支持实时问答，助您全面理解文献。',
    },
    policy: '用户协议',
    privacy: '隐私政策',
    contact: '联系我们',
  },
  basicColumnTitle: basicColumnTitleZhCN,
  menu: {
    home: '首页',
    knows: 'AI搜索问答',
    manage: '证据分析',
    read: '全文精读',
    admin: '企业管理',
    records: '数据分析',
    open: '打开的页面',
    create: '未命名证据集',
  },
  menuHUIZHI: {
    home: '首页',
    knows: '科研选题',
    manage: '证据分析',
    read: '文献解读',
    admin: '企业管理',
    records: '数据分析',
    open: '打开的页面',
    create: '未命名证据集',
  },
  chatPdfManage: {
    upload: {
      single: {
        title: '单篇解读',
        desc: '一键生成PPT，对话式解读全文',
        buttonText: '点击上传PDF',
      },
      multiple: {
        title: '多篇分析',
        desc: '对多篇全文进行综合分析，并生成PPT',
        buttonText: '即将发布',
      },
      loading: '上传中...',
      parsing: '解析中...',
      limit: '文件大小不能超过50MB',
    },
    list: {
      title: '解读列表',
    },
    detail: {
      file_title: {
        abstract: '原文摘要',
        origin: '文件原文',
      },
      gen_ppt: {
        download: '下载解读PPT',
        generating: '正在生成解读PPT',
        generate: '生成解读PPT',
      },
    },
  },
  manage: {
    unnamed: '未命名证据集',
    title: '我的证据集',
    newProjectImport: '新建证据集',
    updateSortLabel: '按更新时间，从新到旧',
    createSortLabel: '按创建时间，从新到旧',
    total: '{total}条',
    des: '支持提问、DOI、PMID、Pubmed检索式、上传PDF、Excel文件导入文献',
  },
  created: '创建于',
  updated: '更新于',
  ago: '之前',
  phone: '手机号',
  bind: '绑定',
  weChat: '微信',
  change: '更换',
  logout: '退出登录',
  confirm: '确定',
  cancel: '取消',
  delete: '删除',
  rename: '重命名',
  recover: '恢复',
  undo: '撤销',
  clearFilter: '清除筛选',
  filter: '筛选',
  pin: {
    left: '固定在左侧',
    right: '固定在右侧',
  },
  hide: '隐藏',
  ascending: '正序',
  descending: '倒序',
  sortDateAsc: '从旧到新',
  sortDateDesc: '从新到旧',
  sortNumberAsc: '从小到大',
  sortNumberDesc: '从大到小',
  selectAll: '全选',
  introductionEvidence: {
    title: '添加证据',
    limit: {
      row: '单个条件最大查找10000条证据',
      info: '单次导入最大支持10000条证据',
    },
    question: '问题',
    finding: '寻找中',
    importAll: '全部导入',
    uploadText: '点此上传PDF文件',
    identified: '已识别到的证据',
    ask: {
      title: 'AI快搜',
      placeholder: '通过提问添加证据，也可以导入指定证据...',
      enter: 'AI搜索',
      transformButton: '转Pubmed检索式',
    },
    doi: {
      title: 'DOI',
      placeholder: '请输入文献DOI',
      enter: '添加',
    },
    pmid: {
      title: 'PMID',
      placeholder: '请输入文献PMID',
      enter: '添加',
    },
    pubmed: {
      title: 'Pubmed检索式',
      placeholder: '请输入Pubmed检索式',
      enter: '添加',
    },
    upload: {
      title: '上传PDF文件',
    },
    excel: {
      title: '上传Excel文件',
      fileRuleTooltip: 'excel首行必须是列名',
    },
    endnoteXml: {
      title: '上传EndNote文件',
      placeholder: '请上传EndNote XML格式的文件',
      enter: '导入',
    },
  },
  excelUploadModal: {
    title: '请选择Excel文件中的【文献标题列】和【文献摘要列】',
    description: 'AI默认根据这两列进行信息抽取',
    label: {
      title: '文献标题列',
      abstract: '文献摘要列',
    },
    selectColumn: '选择Excel文件中的列',
    warnRequireSelectTitle: '请选择【文献标题列】',
    warnSameSelect: '【文献标题列】和【文献摘要列】不能相同',
  },
  export: '导出',
  uploadLocalFiles: '上传PDF文件',
  dropUpload: '将文件拖放至任何位置即可上传',
  projectName: '证据集名称',
  addComment: '添加批注',
  changeWeChatTitle: '更换绑定微信',
  weChatLoginTitle: '微信扫码登录',
  changeVerifyCode: '更换短信验证登录',
  changeWeChatScanCode: '更换微信扫码登录',
  or: '或',
  SMS: '验证码登录',
  PASSWORD: '密码登录',
  login: {
    text: '登录',
    email: {
      change: '密码登录',
    },
  },
  verifyMessageTitle: '验证短信',
  getVerifyCode: '获取验证码',
  verifyCode: '验证码',
  sms: {
    login: {
      phone: {
        place: '请输入手机号',
        error: {
          info: '请输入正确的手机号',
        },
      },
      code: {
        place: '请输入验证码',
      },
    },
  },
  autoRegis: '新用户将自动注册',
  seconds: '秒',
  verifyCodeError: '验证码填写错误',
  machineVerify: '机器验证',
  refresh: '刷新',
  refresh1: '换一换',
  clearConversation: '清空会话',
  pdfParsing: '正在解析PDF，请稍候片刻...',
  enterGraphicVerify: '输入上方的图形验证码',
  email: {
    login: {
      account: '账号',
      pwd: '密码',
      passwordPlaceHolder: '请输入密码',
      placeholder: '请输入邮箱或手机号码',
      placeholder2: '请输入账号',
      tips: '新用户注册',
    },
    register: {
      title: '注册新账号',
      success: {
        msg: '已发送邮箱，请去邮箱链接激活',
      },
      submit: '创建账号',
      setPassword: '设置密码',
      confirmPassword: '确认密码',
      tips: '已经有账号了',
      goLogin: '去登录',
    },
    forget: {
      text: '忘记密码',
      desc: '输入您加入时使用的电子邮件地址，我们将发送您需要重新设置密码。',
      success: {
        msg: '已发送邮箱，请去邮箱链接重置密码',
      },
      submit: '验证并重置',
    },
    reset: {
      text: '重置密码',
      oldPassword: '旧密码',
      password: '新密码',
      confirmPassword: '确认新密码',
    },
    message: {
      validEmail: '请输入正确的邮箱',
      validAccount:
        '6-20位字符；允许字母/数字/@/./-，最多包含一个下划线；不能以.或-开头',
      confirmPassword: {
        empty: '请确认密码',
        inconformity: '两次密码不一致',
      },
    },
  },
  machine: {
    verify: {
      error: '验证码填写有误',
    },
  },
  question: {
    input: {
      placeholder: '关于这些证据我还想知道',
    },
  },
  insert: {
    blank: {
      column: '插入空白列',
    },
  },
  display: {
    fields: '列编辑',
  },
  wantGetInfo: '想获取的信息',
  import: {
    loading: '导入中',
    text: '导入',
    record: '导入记录',
    done: '完成',
    success: {
      info: '导入成功',
    },
  },
  blank: '空白',
  filters: '筛选',
  fileUpload: {
    sizeLimit: '文件应小于{count}',
  },
  project: {
    process: {
      loadingText: '剩余 {count} 条',
      calculating: '计算中..',
      stop: '停止',
      halting: '停止中',
      delete: {
        desc: '是否要中断并取消当前证据集所有正在运行的任务？',
        ok: '确认停止',
      },
      failed: '{count} 失败',
      retry: '重试',
      failedText: 'AI运行失败，点击重试',
    },
    popconfirm: {
      delete: {
        title: '确定删除',
        desc: '确定删除所选证据集吗？',
      },
    },
    prompFields: {
      pdf: 'PDF全文',
    },
    fields: {
      authors: basicColumnTitleZhCN.authors,
      journal: basicColumnTitleZhCN.journal,
      blankImpactFactorValue: '空',
      impactFactor: 'IF值: {value}',
    },
    blankQuestionTitle: '空',
    blankQuestionContent: '空',
    back: '返回',
    analyzing: '解析中...',
    relatedContent: '相关内容',
    relatedTrials: '相关研究',
    references: '引用文献',
    citations: '被引用文献',
    blankColumn: '空白列',
    newBlankColumn: '新空白列',
    newColumnButton: '创建{name}列',
    newColumnInputPlaceholder: '我还想知道…',
    batchCellModal: {
      button: '批量修改',
      title: '批量修改',
      placeholder: {
        selectColumn: '选择列',
        text: '输入文本',
        number: '输入数字',
        date: '输入日期',
      },
      operate: {
        clear: '清空',
        replace: '替换',
        unshift: '在句首增加',
        push: '在句尾增加',
      },
    },
    knows: {
      actionBar: {
        newButton: '与选中证据对话',
        newLimitTip: '证据数量超{count}篇，系统将自动截取前{count}篇进行回答',
      },
      drawer: {
        title: '与选中文献对话',
        close: '关闭会话',
        retry: '重试',
        new: '新会话',
        networkError: '网络中断，点击重试',
        answer: '回答：',
        retryByUserCancel: '用户停止响应',
        stop: '停止响应',
        feedbackTitle: '意见反馈',
        feedbackPlaceHolder: '意见描述',
        input: {
          placeholder: '输入你的问题',
          scopeLabel: '问答范围：',
          chooseScopeTitle: '选择问答范围',
          scopeContentLimitTip: '所选范围篇幅过长，将基于有限内容进行回答',
          maxInputLengthLimitTip: '请优化输入问题字数',
          chooseAtLeastOneColumn: '请至少选择一个列名进行回答',
        },
        evidences: {
          title: '选中证据范围 {count}',
          titleTour: '即将根据您选择的 {count} 篇证据进行回答',
          tourConfirm: '知道了',
          abstract: '摘要',
          deleted: '该证据已经删除',
          selectedFirst: '请先选择证据',
        },
        history: {
          title: '历史会话',
          lengthLabel: '证据范围：',
          lengthValue: '{count} 篇',
        },
      },
    },
    table: {
      details: '详情',
      acquiring: '正在获取',
      configHelper: '配置',
      configHelperDone: '完成',
      uploadFile: '上传文件',
      uploadingFile: '上传中',
      sortingInfo: {
        publishDateAsc: '从旧到新',
        publishDateDesc: '从新到旧',
      },
      filterInfo: {
        headerButton: '筛选{count}个条件：',
        dropdownTitle: '筛选条件',
        itemSelected: '已选 {count} 个值',
      },
      filterMenu: {
        include: '包含{total}个',
        exclude: '不包含{total}个',
        reverse: '反选',
        all: '全选',
      },
      selected: '已经选中 {count} 条证据',
      filterInputPlaceholder: '例如：2020',
      filterUniqueValuesTotal: '{total}个',
      deleteConfirm: {
        description: '确定删除所选 {count} 条证据吗？',
        successMessage: '已删除 {count} 条证据',
      },
    },
    citationModal: {
      format: '格式',
      cancel: '取消',
      copy: '复制',
    },
  },
  records: {
    header: {
      total: '共{total}个',
    },
  },
  expand: '展开',
  fold: '收起',
  upload: {
    tooLarge: {
      error: '文件过大，上传失败',
      limit: {
        image: '图片最大限制为',
      },
    },
  },
  time: {
    unit: {
      now: '刚刚',
      minutes: '分钟',
      hours: '小时',
      days: '天',
      weeks: '周',
      months: '月',
      years: '年',
    },
  },
  ok: '确认',
  prompt: {
    text: '问题描述',
    example: '例如:从文本中提取描述患者特征的单词。',
    placeholder: '输入"@"填入底表字段',
    clearConfirm: {
      title: '确定清空列',
      description:
        '由于您的问题描述中对输出格式要求有变化，该列的结构会改变并清空既往值',
    },
    tooltip1: '您可以只输入问题或指令，我们将默认对title和abstract列进行提问；',
    tooltip2: '您也可以通过"@"选择想要提问的列名进行提问。',
    example1: '示例1:患者的年龄',
    example2:
      '示例2:根据{columnTitle} {columnAbstract}判断纳入的患者是否是NSCLC',
  },
  notification: {
    copied: '复制成功',
    noRowsSelected: '请先勾选数据',
    summarizeFirst: '请先进行总结',
    guideDetailsPageIsUnderConstruction: '指南详情页施工中',
    meetingDetailsPageIsUnderConstruction: '会议详情页施工中',
    isReceivingEvidences: '正在响应中',
  },
  tooltip: {
    openInNewWindow: '新标签打开',
    copyDOI: '复制DOI',
    copyPMID: '复制PMID',
    copyUrl: '复制URL',
    download: '下载',
    generateCitation: '生成Citation',
  },
  copy: '复制',
  months: {
    january: '一月',
    february: '二月',
    march: '三月',
    april: '四月',
    may: '五月',
    june: '六月',
    july: '七月',
    august: '八月',
    september: '九月',
    october: '十月',
    november: '十一月',
    december: '十二月',
  },
  detail: {
    translation: {
      translate: '全文翻译',
      successTip: '翻译成功，请点击下载',
      download: '点击下载',
    },
  },
  // knows module
  knows: {
    thematicReport: '专题报告',
    thematicReportDrawer: {
      analyzeQuestion: '分析问题',
      generateOutline: '生成大纲',
      generatePPT: '生成PPT',
      regeneratePPT: '再次生成PPT',
      history: '历史记录',
    },
    filters: {
      publishedSince: '发表时间自',
      studyType: '研究类型',
      Guidelines: '指南/共识',
      Meta: 'Meta分析/系统性综述',
      RCT: 'RCT',
      ClinicalTrial: '临床试验(非RCT)',
      Case: '病例系列/病例报告',
      Literature: '文献综述(非系统性综述)',
      OtherTypes: '社论等特殊类型',
      BasicResearch: '基础研究',
      ObservationalStudy: '观察性研究',
      IF: 'IF值',
      Q: '{count}区',
      subISTEC: '中科院小类分区',
      JCR: 'JCR分区',
      FullText: '是否有全文',
    },
    generatePptByIssueButton: '生成学术演讲PPT',
    downloadPptByIssueButton: '下载学术演讲PPT',
    searchPlaceholder: '输入医学相关的问题',
    searchSubmit: 'AI搜索',
    tabHotQuestions: '大家在问',
    tabHistory: '我提问过',
    clearHistory: '清空',
    backTop: '顶部',
    summary: '总结',
    evidenceSearching: '寻找证据范围: {count}篇',
    relevantEvidence: '已锁定证据范围: {count}篇',
    managePapers: '管理文献',
    summaryMode: {
      clinical: '临床建议',
      research: '学术综述',
      popular_science: '科普教育',
    },
    dataScope: {
      MEETING: '会议',
      PAPER: '英文文献',
      PAPER_CN: '中文文献',
      TOP_PAPER: '优质文献',
      GUIDE: '指南',
    },
    evidenceList: {
      summaryError: 'AI总结失败',
      summaryProcessing: 'AI总结中...',
      sort_option: {
        by_time: '时间从近到远',
        by_if: 'IF值从高到低',
        by_relativity: '相关性从高到低',
      },
    },
  },
  credits: {
    left: '剩余 {count} credits',
    tips: '一个单元格运行一次预计消耗 1 credit，\n涉及全文的一次消耗10~20credits',
    insufficient2: '您的余额已不足够运行本次操作',
    insufficient:
      '本次操作预计消耗 {count} credits，您的余额已不足够运行本次操作',
    largeAmountRemind: '您本次操作预计消耗 {count} credits',
    largeAmountRemind2:
      '您的操作即将自动运行超过 {count} 个单元格，这可能会产生一定的费用。请您二次确认。',

    usage: {
      tip: '查看Credit用量',
      title: 'Credit用量',
      chat: '文献对话',
      received: '获得 {count} credits',
      helpColumn: 'AI提取',
      noMore: '没有更多了',
    },
  },
  placeholder: {
    text: '请输入',
    select: '请选择包含目标信息的列',
  },
  priority: {
    HIGH_IF_VALUE: '影响因子优先',
    HIGH_RERANK_SCORE: '系统默认',
    HIGH_PUBLISH_DATE: '最新时间优先',
  },
  setting: {
    title: '设置',
    language: {
      title: '语言',
      desc: '设置您的首选语言，用于界面和AI响应。',
      lang: {
        chinese: '中文',
        english: '英语',
      },
    },
  },
  userInfo: {
    level: {
      label: '当前方案',
      upgrade: '升级',
      renewal: '续费',
      creditPayTip: '仅专业版可充值',

      free: '免费版',
      pro: '专业版',
      bi_pro: '精选版',
      personal: '移动版',
      professional: '专业版',
      ultimate: '旗舰版',
    },
    payment: {
      amountPayable: '应付金额',
      productName: '商品名',
      startDate: '生效日期',
      endDate: '时效日期',
      scanToPay: '请扫码完成支付',
      freeGift: '免费赠送',
      payCodeText: '请添加微信客服购买权益',
    },
    permission: {
      upgrade: '解锁高级权益',
      renewal: '续费 KnowS 专业版',
      baseScope: '涵盖指南、中英文文献',
      groupAsk: 'AI搜索',
      chat: 'AI搜索提问',
      scope: '涵盖会议',
      evidenceList: '浏览证据列表',
      evidenceExport: '导出证据列表',
      evidenceSummary: 'AI生成证据总结（临床、学术、科普）',
      groupRead: 'AI解读',
      evidenceDetail: '查阅证据详情',
      translate: '中英文翻译',
      aiRead: 'AI一键解读',
      genWord: '生成Word报告',
      genPpt: '生成PPT演示',
      fullText: '全文对话式精读',
      uploadPdf: '支持上传PDF文档进行解读',
      morePaperFilter: '大量文献快速精筛（仅限PC端）',
      multiPaperChat: '多篇文献对话解读（仅限PC端）',
      groupCommon: '通用',
      terminal: '手机、PC双端同步使用',
      share: '证据与总结分享',
      response: '高峰时段优先响应',
      customerService: '一对一专属客服',
      trial: '抢先体验更多AI功能',
    },
  },
});
