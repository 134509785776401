// 运行时配置
import { SLS_CLIENT } from '@aliyun-sls/web-browser';
import { AxiosError, RequestConfig, getLocale } from '@umijs/max';
import { ConfigProvider, message } from 'antd';
import JSONBigInt from 'json-bigint';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { fetchInitialData } from './services/user';
import { ChannelConfig } from './services/user/typings';
import { useComponentStore, useUserStore } from './store';
import { getEmailLoginMode, jumpLandingPage } from './utils/jump';
import { API_ENV, BASE_URL, ResponseWrap } from './utils/request';

const SLS_ENV = API_ENV; // process.env.NODE_ENV === 'development' ? 'dev' : 'prod';
SLS_CLIENT.init({
  host: 'cn-shanghai.log.aliyuncs.com',
  project: 'log-service-project-knows-group-cn-shanghai',
  logstore: `medknows-web-${SLS_ENV}-raw`,
  workspace: `medknows-web-${SLS_ENV}`,
  env: SLS_ENV,
  service: 'web',
  enableRuntimeError: true,
  enableResourcePerf: true,
  enableRequest: true,
  enablePerf: true,
  enableTrace: true,
});

const JSONBigIntStringAsString = JSONBigInt({ storeAsString: true });

message.config({
  maxCount: 2,
});

function isJsonString(str: any): str is string {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
const ignore = ['/', '/welcome', '/welcome/en'];

export const request: RequestConfig = {
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  transformResponse: (resp) => {
    if (!isJsonString(resp)) {
      return resp;
    }
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt
    // https://stackoverflow.com/questions/43787712/axios-how-to-deal-with-big-integers
    // replace big int to string
    return JSONBigIntStringAsString.parse(resp);
  },
  requestInterceptors: [
    (reqConfig) => {
      const token = localStorage.getItem('token');
      return {
        ...reqConfig,
        headers: {
          Authorization: `Bearer ${token}`,
          ...reqConfig.headers,
          'Accept-Language': getLocale(),
        },
      };
    },
  ],
  responseInterceptors: [
    (resp) => {
      if (
        resp &&
        resp.status === 200 &&
        (resp?.data?.code === 20243 || resp?.data?.code === 20245)
      ) {
        // 没有权限
        useComponentStore.getState().onResetOpen('NoPermissionModal');
      }
      return resp;
    },
  ],

  errorConfig: {
    errorHandler: (err) => {
      const error = err as AxiosError<ResponseWrap<any>>;
      // if (error.response?.status !== 200 && error.response?.status !== 403) {
      // if (ignore.includes(location.pathname)) {
      //   jumpLandingPage();
      //   return;
      // }
      // }
      if (error.response?.status === 401) {
        // 忽略knows的未登录，防止未绑手机号弹出登录框
        if (error.response?.data.code === 'A0220') {
          return;
        }

        const landingPage = localStorage.getItem('third_landing_page');
        if (landingPage) {
          // 没有接口登录的租户，一律跳走
          jumpLandingPage();
          return;
        }
        const isOpened = !!useComponentStore.getState().state?.LoginModal?.open;
        if (isOpened) return;
        useUserStore.getState().clearUser();
        useComponentStore.getState().onResetOpen('LoginModal');
      }
      if (error.response?.status === 403) {
        // if (error.response?.data.code === 'A0239') {
        //   message.error(error.response?.data.msg);
        // }
        if (error.response?.data.code === 66255) {
          // 绑定手机号
          const isOpened =
            !!useComponentStore.getState().state?.BindPhoneModal?.open;
          if (isOpened) return;
          useComponentStore.getState().onResetOpen('BindPhoneModal', {
            keyboard: false,
            closable: false,
            maskClosable: false,
          });
          if (location.pathname === '/') {
            window.location.replace('/home');
          }
        }
        if (error.response?.data.code === 20246) {
          // 补充用户信息
          const isOpened =
            !!useComponentStore.getState().state?.UpdateUserInfoModal?.open;
          if (isOpened) return;
          useComponentStore.getState().onResetOpen('UpdateUserInfoModal', {
            keyboard: false,
            closable: false,
            maskClosable: false,
          });
          if (location.pathname === '/') {
            window.location.replace('/home');
          }
        }
      }
    },
  },
  // TODO: responseInterceptors
};

export async function getInitialState(): Promise<ChannelConfig> {
  if (
    window.location.protocol === 'http:' &&
    !window.location.host.includes('dev.') &&
    !window.location.host.includes('localhost')
  ) {
    // http跳转https
    const httpsUrl = window.location.href.replace('http://', 'https://');
    window.location.href = httpsUrl;
  }

  const queries = Object.fromEntries(
    new URLSearchParams(window.location.search.split('?')?.[1] || ''),
  );

  const initialDataRes = await fetchInitialData({
    // channel: '4cb92e794c744f70b31e2f440f9f937f', // 辉瑞
    channel:
      queries.channel ||
      (getEmailLoginMode() ? '4de46be96c7f49938de86c38a1799507' : undefined), // 判断是不是mediflow.ai 的channel_id: 4de46be96c7f49938de86c38a1799507
  });
  const initialData = {
    ...initialDataRes.data.config,
    channel_id: initialDataRes.data.channel_id,
  };
  // const initialData = {
  //   theme: {
  //     name: '朗视界沐光明',
  //     style: {
  //       '--color-primary': '#1c9b61',
  //       '--color-primary-sub': '#1c9b61',
  //       '--color-primary-light': '#dbefe8',
  //     },

  //     logo: 'https://nullht-official-website.oss-cn-shanghai.aliyuncs.com/KNOWS_DOCTOR/THEME_CONFIG/logo/mazuijia_logo.svg',
  //     // name: '麻醉+',
  //     text_logo:
  //       'https://nullht-official-website.oss-cn-shanghai.aliyuncs.com/KNOWS_DOCTOR/THEME_CONFIG/logo/mazuijia_text_logo.png',
  //     // logo: 'https://nullht-official-website.oss-cn-shanghai.aliyuncs.com/KNOWS_DOCTOR/THEME_CONFIG/logo/kanghong.svg',
  //     // text_logo:
  //     //   'https://nullht-official-website.oss-cn-shanghai.aliyuncs.com/KNOWS_DOCTOR/THEME_CONFIG/logo.svg',
  //     chat_bot_icon:
  //       'https://nullht-official-website.oss-cn-shanghai.aliyuncs.com/KNOWS_DOCTOR/THEME_CONFIG/logo/kanghong.svg',
  //     chat_bot_name: 'AI医学助手',
  //   },
  //   landing: 'KANGHONG',
  //   landing_page: 'https://caa.iceriverbj.com/v1/index.html#/',

  //   functions: {
  //     hidden_side_menu: false,
  //     show_fulltext_translate_button: true,
  //   },
  //   pay_modal: {
  //     title: '权限不足',
  //     show_level: false, // 显示等级
  //     desc: '您的体验额度已达上限，如需继续使用，请升级账户',
  //     // img: 'https://nullht-official-website.oss-cn-shanghai.aliyuncs.com/KNOWS_DOCTOR/THEME_CONFIG/knows_qrcode.png',
  //     // link: {
  //     //   url: 'https://wuk7uud7j2.feishu.cn/docx/A1ZJdKEwzoCJD3xKHNgcTVcZndc',
  //     //   label: '申请权限',
  //     // },
  //   },
  //   auth_methods: ['PASSWORD', 'SMS'],
  //   protocols: [
  //     {
  //       id: '7acbaa003c644597896e19ff371e4d37',
  //       name: '平台产品用户协议',
  //       state: false,
  //       url: 'https://dev-knows.nullht.com/protocol?type=UserAgreement&name=平台产品',
  //     },
  //     {
  //       id: 'b44bde293d9c4339ad50cde00c574ee3',
  //       name: '平台产品隐私政策',
  //       state: false,
  //       url: 'https://dev-knows.nullht.com/protocol?type=PrivacyAgreement&name=平台产品',
  //     },
  //   ],
  // };

  if (initialData.pay_modal) {
    localStorage.setItem('pay_modal', JSON.stringify(initialData.pay_modal));
  }

  localStorage.setItem(
    'third_landing_page',
    initialData?.landing_page || '/welcome',
  );
  localStorage.setItem('third_home_page', initialData?.home_page || '/home');

  if (initialData?.theme?.style) {
    // 设置全局css变量
    Object.entries(initialData.theme.style).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value as string);
    });
  }

  if (initialData?.theme?.logo) {
    // 监听 theme 并修改 favicon
    const favicon = document.querySelector("link[rel='shortcut icon']");
    if (favicon) {
      favicon.setAttribute('href', initialData.theme.logo);
    }
  }

  if (initialData.default_language) {
    localStorage.setItem('umi_locale', initialData.default_language);
  }

  return initialData;
}

const AppContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState({
    colorPrimary: '#1890ff',
  });

  useEffect(() => {
    const updateTheme = () => {
      setTheme({
        colorPrimary:
          getComputedStyle(document.documentElement)
            .getPropertyValue('--color-primary')
            .trim() || '#1890ff',
      });
    };

    const observer = new MutationObserver(updateTheme);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['style'],
    });

    updateTheme(); // 初次执行一次
    return () => observer.disconnect();
  }, []);

  return (
    <ConfigProvider
      prefixCls="mediflow"
      theme={{
        token: {
          ...theme,
          // colorPrimary: 'var(--color-primary)',
        },
        components: {
          Button: {
            borderRadius: 5,
            controlHeight: 40,
          },
          Popover: {
            borderRadiusLG: 8,
            boxShadowSecondary:
              '0px 24px 48px -12px rgba(11, 14, 28, 0.12), 0px 0px 24px 0px rgba(11, 14, 28, 0.08)',
          },
          Modal: {
            borderRadiusLG: 20,
          },
          Input: {
            boxShadow: 'unset',
          },
          Tooltip: {
            colorBgSpotlight: 'rgba(0, 0, 0, 0.80)',
            paddingSM: 8,
            paddingXS: 10,
          },
        },
      }}
    >
      {children} <Toaster position="bottom-right" />
    </ConfigProvider>
  );
};

export function rootContainer(container: React.ReactNode) {
  return <AppContainer>{container}</AppContainer>;
}
