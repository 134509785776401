import { v4 as uuid } from 'uuid';
import { combine } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

type ComponentState = {
  open?: boolean;
  /** 用于重置组件内部 */
  key?: string;
  closable?: boolean;
  maskClosable?: boolean;
  keyboard?: boolean;
};

const componentCreator = combine(
  {
    state: {} as Record<string, ComponentState>,
  },
  function (set, get) {
    return {
      toggle(key: string, value?: boolean) {
        set((prev) => {
          return {
            state: {
              ...prev.state,
              [key]: {
                ...prev.state[key],
                open: value ?? !prev.state[key]?.open,
              },
            },
          };
        });
      },
      onOpen(key: string, other?: Omit<ComponentState, 'open' | 'key'>) {
        const that = this || get();
        that.toggle(key, true);
        set((prev) => ({
          state: {
            ...prev.state,
            [key]: {
              ...prev.state[key],
              ...other,
            },
          },
        }));
      },
      onClose(key: string) {
        const that = this || get();
        that.toggle(key, false);
      },
      onReset(key: string) {
        const state = get().state;
        if (!state[key]) {
          state[key] = {};
        }
        state[key].key = uuid();
        set({
          state: Object.assign({}, state),
        });
      },
      onResetOpen(key: string, other?: Omit<ComponentState, 'open' | 'key'>) {
        const { onOpen, onReset } = this || get();
        onReset(key);
        onOpen(key, other);
      },
    };
  },
);

export const useComponentStore = createWithEqualityFn(componentCreator);
