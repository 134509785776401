import {
  MenuType,
  ProjectValue,
  useNewProjectsStore,
} from '@/store/newProjects';
import { history } from '@umijs/max';

export const jumpInterpretationDetail = (card: {
  title?: string;
  dataId: string;
  parentId: string;
  type: 'single' | 'multi';
}) => {
  const id = card.dataId;
  const { value, createProject, updateProject } =
    useNewProjectsStore.getState();
  const existProject = value.find((item) => item.id === id);
  if (!existProject) {
    createProject({
      id,
      title: card.title,
      type: 'interpretation',
      parentId: card.type === 'single' ? '' : card.parentId,
    });
  } else {
    updateProject(id, {});
  }
  if (card.type === 'single') {
    history.push(
      '/interpretation/detail/' + card.parentId + '/data/' + card.dataId,
    );
  } else {
    history.push('/interpretation/detail/' + card.parentId);
  }
};

export const jumpDetail = (card: { key: string; title?: string }) => {
  const id = card.key;
  const { value, createProject, updateProject } =
    useNewProjectsStore.getState();
  const existProject = value.find((item) => item.id === id);
  if (!existProject) {
    createProject({ id, menuKey: id, title: card.title });
  } else {
    updateProject(id, {});
  }
  if (existProject) {
    history.push(existProject.path);
  } else {
    history.push('/project/detail/' + id);
  }
};
export const getEmailLoginMode = () => {
  return (
    location.host.includes('mediflow.ai') || location.pathname === '/welcome/en'
  );
};

export const jumpLandingPage = () => {
  const landingPage = localStorage.getItem('third_landing_page');
  // TODO: 判断用户跳转到那个Landing Page
  if (landingPage) {
    window.location.replace(landingPage);
  } else {
    if (getEmailLoginMode()) {
      history.replace('/welcome/en');
    } else {
      history.replace('/welcome');
    }
  }
};
export const isLandingPage = () => {
  return (
    history.location.pathname === '/welcome' ||
    history.location.pathname === '/welcome/en' ||
    history.location.pathname === localStorage.getItem('third_landing_page')
  );
};

/** 登录成功后处理 */
export const onLoginSuccess = () => {
  if (isLandingPage()) {
    console.log('onLoginSuccess');
    window.location.replace(localStorage.getItem('third_home_page') || '/home');
  } else {
    window.location.reload();
  }
};

export const genParentPath = (type: ProjectValue['type']) => {
  if (type === 'knows') {
    return '/knows/search/';
  }
  if (type === 'interpretation') {
    return '/interpretation/detail/';
  }
  return '/project/detail/';
};

export const genAllPath = ({
  type,
  id,
  parentId,
  searchQuery,
}: {
  type: ProjectValue['type'];
  id: string;
  parentId?: string | null;
  searchQuery?: string | null;
}) => {
  let basePath = genParentPath(type);

  const path = parentId ? basePath + parentId + '/data/' + id : basePath + id;
  if (searchQuery) {
    return path + searchQuery;
  }
  return path;
};

/** 跳转sub menu的页面 */
export const jumpSubDetail = (
  parentId: string | null,
  subId: string,
  type: MenuType,
  searchQuery: string = '',
) => {
  if (!parentId) {
    history.push(genAllPath({ type, id: subId, parentId, searchQuery }));
    return;
  }
  const project = useNewProjectsStore.getState().findProject(parentId);
  if (!project) return;

  const allPath = genAllPath({
    type: project.type,
    id: subId,
    parentId,
    searchQuery,
  });

  return history.push(allPath);
};
