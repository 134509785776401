import { FileType } from '@/components/Icon';
import { ShortcutKey } from './typings';

/** 最宽松，用于接收短信 */
export const phoneReg = /^(?:(?:\+|00)86)?1\d{10}$/;

export const isPhone = (value?: string) => value && phoneReg.test(value);

export const getFileType = (fileName: string) => {
  return fileName?.split('.').pop() as FileType;
};

export function getAccpetFileTypes(accept: string): string[] {
  return accept.split(',').map((item) => item.replace('.', ''));
}

export const tryCatch = async (promise: () => Promise<void>) => {
  try {
    await promise();
  } catch (e) {}
};

/**
 * 解析json不报错
 * @param json
 * @param fallback 字符串为空或解析失败时返回的值
 * @returns
 */
export function safeParseJson<T>(json: string | null, fallback: T): T {
  if (!json) {
    return fallback;
  }
  try {
    return JSON.parse(json) as T;
  } catch {
    return fallback;
  }
}

/**
 * 根据文件地址下载文件
 */
export function downloadByUrl(data: {
  url: string;
  target?: string;
  fileName?: string;
}) {
  let { url, target = '_blank', fileName } = data;
  const isChrome =
    window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const isSafari =
    window.navigator.userAgent.toLowerCase().indexOf('safari') > -1;

  if (/(iP)/g.test(window.navigator.userAgent)) {
    console.error('您的浏览器不支持下载!');
    return false;
  }
  if (isChrome || isSafari) {
    const link = document.createElement('a');
    link.href = url;
    link.target = target;

    if (link.download !== undefined) {
      link.download =
        fileName || url.substring(url.lastIndexOf('/') + 1, url.length);
    }

    if (document.createEvent) {
      const e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      link.dispatchEvent(e);
      return true;
    }
  }
  if (url.indexOf('?') === -1) {
    url += '?download';
  }

  window.open(url, target);
  return true;
}

export function openPdfInNewWindow(url: string) {
  window.open(`/pdf.js/web/viewer.html?file=${encodeURIComponent(url)}`);
}

/** 是否是一个有效的邮箱 */
export function validateEmail(email?: string) {
  if (!email) return false;
  const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regExp.test(email);
}

/** 判断是否是mac */
export function isMacos() {
  const agent = navigator.userAgent.toLowerCase();
  return /macintosh|mac os x/i.test(agent);
}

/** win和mac分别用不同的展示和快捷键 */
export const replaceShortcut = (key: ShortcutKey) => {
  if (isMacos()) {
    return {
      key,
      text: '⌘',
    };
  }
  return {
    key: key.replace('meta', 'ctrl'),
    text: 'Ctrl',
  };
};

/** 一个文件是否是pdf */
export function isPdfFile(file: File) {
  const { type, name } = file;
  return type === 'application/pdf' && name.endsWith('.pdf');
}

export const EXCEL_FILE_ACCEPT = '.xlsx,.xls';

/** 一个文件是否是excel */
export function isExcelFile(file: File) {
  const { name } = file;
  return name.endsWith('.xlsx') || name.endsWith('.xls');
}

export function formatNumberToLocaleString(num?: number | null) {
  if (!num) {
    return 0;
  }
  return num.toLocaleString();
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

export function isSuper() {
  return window.location.host.includes('superadmin');
}

/** 判断文件是否是 EndNote XML */
export function isEndnoteXMLFile(file: File) {
  const { name } = file;
  return name.toLowerCase().endsWith('.xml');
}
